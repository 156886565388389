import './App.css';

function App() {
  return (
    <div className="App">
      <aside
        className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark bg-white">
        <div className="collapse navbar-collapse w-auto h-auto max-height-vh-100 h-100">
          <ul className="navbar-nav">
            <li className="text-white nav-item"><p>Test Item</p></li>
            <li className="text-white nav-link active"><p>Test Item</p></li>
          </ul>
        </div>
      </aside>

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 position-relative z-index-2">
              <p>TEST</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
